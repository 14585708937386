import { BusinessTypeEnum } from "@/enums/customer/BusinessTypeEnum";
import { CompanyTypesEnum } from "@/enums/customer/CompanyTypesEnum";
import { CustomerPriorityEnum } from "@/enums/customer/CustomerPriorityEnum";
import { YearlyRevenueEnum } from "@/enums/customer/YearlyRevenueEnum";
import { priorityLabel } from "./customer/priorityLabel";

export const companySizeOptions = [
  {
    value: "0-50",
    label: "0-50",
    optionText: "0 - 50 People",
  },
  {
    value: "50-100",
    label: "50-100",
    optionText: "50 - 100 People",
  },
  {
    value: "100-250",
    label: "100-250",
    optionText: "100 - 250 People",
  },
  {
    value: "250-500",
    label: "250-500",
    optionText: "250 - 500 People",
  },
  {
    value: "500+",
    label: "500+",
    optionText: "500+ People",
  },
];

export const industryOptions = [
  {
    value: "Aviation and space",
    label: "Aviation and space",
  },
  {
    value: "Automation",
    label: "Automation",
  },
  {
    value: "Digital printing",
    label: "Digital printing",
  },
  {
    value: "Medical devices",
    label: "Medical devices",
  },
  {
    value: "Metrology",
    label: "Metrology",
  },
  {
    value: "Oil and gas",
    label: "Oil and gas",
  },
  {
    value: "Packing",
    label: "Packing",
  },
  {
    value: "Robotics",
    label: "Robotics",
  },
  {
    value: "Semiconductor",
    label: "Semiconductor",
  },
  {
    value: "Other",
    label: "Other",
  },
];

export const businessTypeOptions = [
  {
    value: BusinessTypeEnum.PRODUCT_DEVELOPER,
    label: "Product developer",
  },
  {
    value: BusinessTypeEnum.PRODUCT_MANUFACTURER,
    label: "Product manufacturer",
  },
  {
    value: BusinessTypeEnum.CONTRACT_MANUFACTURER,
    label: "Contract manufacturer",
  },
];

export const companyTypeOptions = [
  {
    value: CompanyTypesEnum.STARTUP,
    label: "Startup",
  },
  {
    value: CompanyTypesEnum.PRIVATE,
    label: "Private",
  },
  {
    value: CompanyTypesEnum.PUBLIC,
    label: "Public",
  },
];

export const revenueOptions = [
  {
    value: YearlyRevenueEnum.MINIMAL,
    label: "$ 0-50K",
  },
  {
    value: YearlyRevenueEnum.SMALL,
    label: "$ 50K-100K",
  },
  {
    value: YearlyRevenueEnum.MEDIUM,
    label: "$ 100K-250K",
  },
  {
    value: YearlyRevenueEnum.LARGE,
    label: "$ 250K-500K",
  },
  {
    value: YearlyRevenueEnum.LARGE_PLUS,
    label: "$ 500K+",
  },
];

export const priorityGroupOptions = [
  {
    value: CustomerPriorityEnum.LOW,
    label: priorityLabel.LOW,
  },
  {
    value: CustomerPriorityEnum.MEDIUM,
    label: priorityLabel.MEDIUM,
  },
  {
    value: CustomerPriorityEnum.KEY_ACCOUNT,
    label: priorityLabel.KEY_ACCOUNT,
  },
];
